import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, getFormValues, change } from 'redux-form';
import { number } from 'utils/validations';
import ReactLoading from 'react-loading';
import {
  TableMoreRowWrapper,
  TableMoreRowContent,
  TableMoreRowContentLeft,
  TableMoreRowContentRight,
  TableMoreRightSection,
  FormContent,
  TableMoreTitleWrapper,
  MobileMoreFormBtns,
  MobileMoreFormBtn,
  TableMoreLeftSection,
  TableMoreLeftSectionContent,
  TableMoreLeftTopSection,
  TableMoreLeftBottomSection,
  TooltipWrapper,
  TableMoreBtns,
  TableMoreBtn,
  TableMoreTitle,
  TableMoreLeftBottomSectionHeader,
  TableMoreMobileIcons
} from '../../Stake/Table/styles/TableComponents';
import { LoadingButton, LoadingButtonCircle } from '../../Modals/styles/ModalsComponents';
import { BtnArrow, LinkArrow, LinkArrowWhite, MetaMaskIcon } from 'assets';
import { setTxModalOpen, setTxModalType, setTxModalData, setTxModalStatus, setTxModalLoading } from 'redux/actions/tableData';
import { setAddress, setNetwork, setBalance, setWalletAndWeb3 } from 'redux/actions/ethereum';
import { fromWei, initOnboard, addTokenToWallet } from 'services';
import { roundNumber, isGreaterThan, isEqualTo, isLessThan, readyToTransact } from 'utils';
import { ModeThemes, ETHorMaticCheck } from 'config';
import i18n from '../../locale/i18n';

const InputField = ({ input, type, className, meta: { touched, error } }) => (
  <div>
    {touched && error ? (
      <input {...input} type={type} className={`${className} InputStylingError`} />
    ) : (
      <input {...input} type={type} className={`${className} InputStyling`} />
    )}
    {touched && error && <span></span>}
  </div>
);

let TableMoreRow = ({
  name,
  type,
  trancheId,
  apyStatus,
  apy,
  sliceAPY,
  netAPY,
  contractAddress,
  cryptoType,
  dividendType,
  underlyingDecimals,
  protocolAPY,
  buyerTokenBalance,
  trancheToken,
  trancheRate,
  buyerCoinAddress,
  trancheTokenAddress,
  trancheValue,
  // isDepositApproved,
  // isWithdrawApproved,
  // setDepositApproved,
  // setWithdrawApproved,
  // redux
  ethereum: { balance, tokenBalance, trancheAllowance, txOngoing, wallet },
  data: { txOngoingData, trancheCard },
  setAddress,
  setNetwork,
  setBalance,
  network,
  setWalletAndWeb3,
  setTxModalOpen,
  setTxModalType,
  setTxModalStatus,
  setTxModalData,
  setTxModalLoading,
  change,
  formValues,
  theme,
  analyticsLink,
  explorerLink,
  tokenIcon
}) => {
  const [isEth, setIsEth] = useState(false);
  const [isDepositApproved, setDepositApproved] = useState(false);
  const [isWithdrawApproved, setWithdrawApproved] = useState(false);
  const [depositBalanceCheck, setDepositBalanceCheck] = useState('');
  const [withdrawBalanceCheck, setWithdrawBalanceCheck] = useState('');
  const [formType, setFormType] = useState('deposit');
  const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
  const [TooltipToggle, setTooltipToggle] = useState('');
  let trancheTokenBalance = tokenBalance[trancheTokenAddress] && fromWei(tokenBalance[trancheTokenAddress]);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 992);
  };

  const tooltipToggle = (val) => {
    setTooltipToggle(val);
  };

  const onboard = initOnboard({
    address: setAddress,
    network: setNetwork,
    balance: setBalance,
    wallet: setWalletAndWeb3
  });
  const openModal = async (txType, isDeposit) => {
    const ready = await readyToTransact(wallet, onboard);
    if (!ready) return;
    setTxModalType(txType);
    setTxModalOpen(true);
    setTxModalData({
      name: name.split('-').length > 1 ? name.split('-')[1].trim() : name.split('-')[0].trim(),
      contractAddress,
      trancheId,
      trancheCard: trancheCard.id,
      trancheType: type,
      apyStatus,
      cryptoType,
      trancheValue,
      trancheToken,
      dividendType,
      apy,
      protocolAPY,
      sliceAPY,
      netAPY,
      isDeposit,
      isDepositApproved,
      isWithdrawApproved,
      buyerCoinAddress,
      trancheTokenAddress,
      buyerTokenBalance,
      FTMBalance: cryptoType === 'WFTM' ? balance : '0'
    });
  };

  // const closeModal = () => {
  //   setTxModalOpen(false);
  //   setTxModalStatus('initialState');
  //   setTxModalLoading(false);
  // };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (trancheAllowance[contractAddress]) {
      if (ETHorMaticCheck.indexOf(cryptoType) !== -1) {
        setIsEth(true);
        setDepositApproved(true);
        setWithdrawApproved(trancheAllowance[contractAddress][trancheTokenAddress]);
        change('withdrawIsApproved', trancheAllowance[contractAddress][trancheTokenAddress]);
      } else {
        setIsEth(false);
        setDepositApproved(trancheAllowance[contractAddress][buyerCoinAddress]);
        setWithdrawApproved(trancheAllowance[contractAddress][trancheTokenAddress]);
        change('depositIsApproved', trancheAllowance[contractAddress][buyerCoinAddress]);
        change('withdrawIsApproved', trancheAllowance[contractAddress][trancheTokenAddress]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoType, buyerCoinAddress, trancheTokenAddress, trancheAllowance, setDepositApproved, setWithdrawApproved]);

  const setMaxAmount = useCallback(
    (e, type) => {
      // e.preventDefault();
      if (type) {
        change('depositAmount', buyerTokenBalance);
        isEqualTo(buyerTokenBalance, 0) ? setDepositBalanceCheck('InputStylingError') : setDepositBalanceCheck('');
      } else {
        change('withdrawAmount', trancheTokenBalance);
        isEqualTo(trancheTokenBalance, 0) ? setWithdrawBalanceCheck('InputStylingError') : setWithdrawBalanceCheck('');
      }
    },
    [buyerTokenBalance, trancheTokenBalance, change]
  );

  const addingTokenToWallet = async () => {
    const addToken = await addTokenToWallet(trancheTokenAddress, trancheToken, 18, tokenIcon);
  }


  const handleInputChange = (newValue, type) => {
    if (type) {
      isGreaterThan(newValue, buyerTokenBalance || 0) || isLessThan(newValue, 0)
        ? setDepositBalanceCheck('InputStylingError')
        : setDepositBalanceCheck('');
    } else {
      isGreaterThan(newValue, trancheTokenBalance || 0) || isLessThan(newValue, 0)
        ? setWithdrawBalanceCheck('InputStylingError')
        : setWithdrawBalanceCheck('');
    }
  };

  return (
    <TableMoreRowWrapper>
      <TableMoreRowContent>
        <TableMoreRowContentLeft>
          <TableMoreLeftBottomSection titleColor={ModeThemes[theme].titleColor} value={ModeThemes[theme].textColor}>
          <TableMoreLeftBottomSectionHeader>
            <h2>{type === 'TRANCHE_A' ? i18n.t('tranche.trancheData.fixedRate') : i18n.t('tranche.trancheData.variableRate')}</h2>
          </TableMoreLeftBottomSectionHeader>

            <p>
              {type === 'TRANCHE_A'
                ? `${name} ${i18n.t('tranche.trancheData.isTheSenior')} ${dividendType}  ${i18n.t('tranche.trancheData.token')}. ${i18n.t(
                    'tranche.trancheData.thisTrancheFixed'
                  )} ${roundNumber(apy, 2)}%, ${i18n.t('tranche.trancheData.inAddition')}`
                : `${name} ${i18n.t('tranche.trancheData.isTheJunior')}  ${dividendType}  ${i18n.t('tranche.trancheData.token')}. ${i18n.t(
                    'tranche.trancheData.thisTrancheVariable'
                  )} ${roundNumber(apy, 2)}%, ${i18n.t('tranche.trancheData.inAddition')}`}
            </p>
            {isDesktop &&
              <TableMoreBtns margin="20px 0 0 0">
                <TableMoreBtn as="a" href={analyticsLink} target='_blank' rel='noopener noreferrer' btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin="0 16px 0 0" type="button">View Market Analytics</TableMoreBtn>
                { wallet &&
                  <TableMoreBtn btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin="0 16px 0 0" type="button" onClick={addingTokenToWallet}><img src={MetaMaskIcon} alt="MetamaskIcon"/> Add to wallet</TableMoreBtn>
                }
                <TableMoreBtn as="a" href={explorerLink} target='_blank' rel='noopener noreferrer' btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin="0" type="button">
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.59802 0V1.39981H12.1177L5.60849 7.90891L6.58837 8.88878L13.0976 2.37967V4.89933H14.4974V0H9.59802Z" fill={ModeThemes[theme].TableMoreBtnTextColor}/>
                      <path d="M10.2989 12.6004H1.89984V4.20157H7.49918V2.80176H0.5V14.0002H11.6987V7.00119H10.2989V12.6004Z" fill={ModeThemes[theme].TableMoreBtnTextColor}/>
                  </svg>
                  <h2>View in Explorer</h2>
                  </TableMoreBtn>
              </TableMoreBtns>
            }
          </TableMoreLeftBottomSection>
          { !isDesktop &&
            <TableMoreBtns padding="0" margin="20px 0 0 0">
              <TableMoreBtn as="a" href={analyticsLink} target='_blank' padding="0 10px" rel='noopener noreferrer' textTransform="uppercase;" borderRadius="8px" width="auto" btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin="0 17px 0 0" type="button">Market Analytics</TableMoreBtn>
              { wallet &&
                  <TableMoreBtn  onClick={addingTokenToWallet} rel='noopener noreferrer' borderRadius="8px" width="auto" btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin="0 17px 0 0" type="button">
                  <img src={MetaMaskIcon} alt="MetamaskIcon"/> 
                </TableMoreBtn>
              }
              <TableMoreBtn as="a" href={explorerLink} target='_blank' rel='noopener noreferrer' borderRadius="8px" width="auto" btnColor={ModeThemes[theme].TableMoreBtnColor} textColor={ModeThemes[theme].TableMoreBtnTextColor} margin="0" type="button">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.59802 0V1.39981H12.1177L5.60849 7.90891L6.58837 8.88878L13.0976 2.37967V4.89933H14.4974V0H9.59802Z" fill={ModeThemes[theme].TableMoreBtnTextColor}/>
                    <path d="M10.2989 12.6004H1.89984V4.20157H7.49918V2.80176H0.5V14.0002H11.6987V7.00119H10.2989V12.6004Z" fill={ModeThemes[theme].TableMoreBtnTextColor}/>
                </svg>
              </TableMoreBtn>
              
            </TableMoreBtns>
          }
        </TableMoreRowContentLeft>
        {isDesktop ? (
          <TableMoreRowContentRight>
            <TableMoreRightSection
              color={ModeThemes[theme].dropDownBorder}
              disabledBackground={ModeThemes[theme].inputDisabledBackground}
              btn={ModeThemes[theme].backgroundBorder}
              textColor={ModeThemes[theme].titleColor}
              // loading={txOngoing}
              disabled={!isDepositApproved}
              approved={!isDepositApproved}
              disabledColor={ModeThemes[theme].DisabledBtn}
              disabledTextColor={ModeThemes[theme].DisabledBtnText}
            >
              {/* {txOngoing && (
                <div>
                  <ReactLoading type={'spin'} color={ModeThemes[theme].loadingSpinner} />
                </div>
              )} */}
              <TableMoreTitleWrapper color={ModeThemes[theme].dropDownText} theme={theme}>
                <TableMoreTitle textwithBtn={cryptoType === 'WFTM'} theme={theme}>
                  <h2>{i18n.t('tranche.trancheData.deposit')}
                    {cryptoType === 'WFTM' && <button onClick={() => openModal('trancheWFTM', true)}>Wrap</button>}
                  </h2>
                  <h2>
                    {i18n.t('tranche.trancheData.balance')}: {buyerTokenBalance ? roundNumber(buyerTokenBalance) : '0'}<span>{cryptoType}</span>
                  </h2>
                </TableMoreTitle>
                {/* <CheckboxWrapper hidden={isEth}>
                  <h2>{isDepositApproved ? i18n.t('tranche.trancheData.enabled') : i18n.t('tranche.trancheData.disabled')}</h2>
                  <CheckboxContent disabled={txOngoing}>
                    <Field
                      component='input'
                      type='checkbox'
                      name='depositIsApproved'
                      id='depositIsApproved'
                      checked={isDepositApproved}
                      disabled={txOngoing}
                    />
                    <label
                      onClick={txOngoing ? () => {} : (e) => approveContract(true, isDepositApproved, e)}
                      htmlFor='depositIsApproved'
                    ></label>
                  </CheckboxContent>
                </CheckboxWrapper> */}
              </TableMoreTitleWrapper>

              <Form onSubmit={(e) => e.preventDefault()}>
                <FormContent color={ModeThemes[theme].dropDownText} background={ModeThemes[theme].inputBackground}>
                  <Field
                    component={InputField}
                    validate={[number]}
                    onChange={(e, newValue) => handleInputChange(newValue, true)}
                    disabled={!isDepositApproved}
                    className={depositBalanceCheck}
                    name='depositAmount'
                    type='number'
                    step='0.001'
                  />
                  {!isEth && <h2 onClick={isDepositApproved ? (e) => setMaxAmount(e, true) : undefined}>{i18n.t('tranche.trancheData.max')}</h2>}
                </FormContent>
                {isDepositApproved ? (
                  txOngoing && txOngoingData.isDeposit === true && txOngoingData.trancheCardId === trancheCard.id ? (
                    <button>
                      <LoadingButton>
                        {[...Array(4).keys()].map((idx) => {
                          return <LoadingButtonCircle i={idx + 1}></LoadingButtonCircle>;
                        })}
                      </LoadingButton>
                    </button>
                  ) : (
                    <button
                      onClick={() => openModal('trancheConfirm', true)}
                      disabled={depositBalanceCheck === 'InputStylingError' || !formValues || +formValues.depositAmount <= 0 || isNaN(+formValues.depositAmount)}
                    >
                      <img src={BtnArrow} alt='arrow' />
                      <h2>{i18n.t('tranche.trancheData.deposit')}</h2>
                    </button>
                  )
                ) : txOngoing && txOngoingData.isDeposit === true && txOngoingData.trancheCardId === trancheCard.id ? (
                  <button>
                    <LoadingButton>
                      {[...Array(4).keys()].map((idx) => {
                        return <LoadingButtonCircle i={idx + 1}></LoadingButtonCircle>;
                      })}
                    </LoadingButton>
                  </button>
                ) : (
                  <button onClick={() => openModal('trancheEnable', true)}>
                    <img src={BtnArrow} alt='arrow' />
                    <h2>Approve</h2>
                  </button>
                )}
              </Form>
            </TableMoreRightSection>
            <TableMoreRightSection
              withdraw
              color={ModeThemes[theme].dropDownBorder}
              disabledBackground={ModeThemes[theme].inputDisabledBackground}
              btn={ModeThemes[theme].backgroundBorder}
              textColor={ModeThemes[theme].titleColor}
              // loading={txOngoing}
              disabled={!isWithdrawApproved}
              approved={!isWithdrawApproved}
              disabledColor={ModeThemes[theme].DisabledBtn}
              disabledTextColor={ModeThemes[theme].DisabledBtnText}
            >
              {/* {txOngoing && (
                <div>
                  <ReactLoading type={'spin'} color={ModeThemes[theme].loadingSpinner} />
                </div>
              )} */}
              <TableMoreTitleWrapper color={ModeThemes[theme].dropDownText}>
                <TableMoreTitle>
                  <h2>{i18n.t('tranche.trancheData.withdraw')}</h2>
                  <h2>
                    {i18n.t('tranche.trancheData.balance')}: {trancheTokenBalance ? roundNumber(trancheTokenBalance) : '0'} <span>{trancheToken}</span>
                  </h2>
                </TableMoreTitle>
                {/* <CheckboxWrapper>
                  <h2>{isWithdrawApproved ? i18n.t('tranche.trancheData.enabled') : i18n.t('tranche.trancheData.disabled')}</h2>
                  <CheckboxContent disabled={txOngoing}>
                    <Field
                      component='input'
                      type='checkbox'
                      name='withdrawIsApproved'
                      id='withdrawIsApproved'
                      checked={isWithdrawApproved}
                      disabled={txOngoing}
                    />
                    <label onClick={txOngoing ? () => {} : (e) => approveContract(false, isWithdrawApproved, e)} htmlFor='withdrawIsApproved'></label>
                  </CheckboxContent>
                </CheckboxWrapper> */}
              </TableMoreTitleWrapper>
              
              <Form onSubmit={(e) => e.preventDefault()}>
                <FormContent
                  color={ModeThemes[theme].dropDownText}
                  background={ModeThemes[theme].inputBackground}
                  disabledBackground={ModeThemes[theme].inputDisabledBackground}
                  btn={ModeThemes[theme].backgroundBorder}
                >
                  <Field
                    component={InputField}
                    validate={[number]}
                    onChange={(e, newValue) => handleInputChange(newValue, false)}
                    disabled={!isWithdrawApproved}
                    className={withdrawBalanceCheck}
                    name='withdrawAmount'
                    type='number'
                    step='0.001'
                  />
                  <h2 onClick={isWithdrawApproved ? (e) => setMaxAmount(e, false) : undefined}>{i18n.t('tranche.trancheData.max')}</h2>
                </FormContent>
                {isWithdrawApproved ? (
                  txOngoing && txOngoingData.isDeposit === false && txOngoingData.trancheCardId === trancheCard.id ? (
                    <button>
                      <LoadingButton>
                        {[...Array(4).keys()].map((idx) => {
                          return <LoadingButtonCircle i={idx + 1}></LoadingButtonCircle>;
                        })}
                      </LoadingButton>
                    </button>
                  ) : (
                    <button
                      onClick={() => openModal('trancheConfirm', false)}
                      disabled={withdrawBalanceCheck === 'InputStylingError' || !formValues || +formValues.withdrawAmount <= 0 || isNaN(+formValues.withdrawAmount)}
                    >
                      <img src={BtnArrow} alt='arrow' />
                      <h2>{i18n.t('tranche.trancheData.withdraw')}</h2>
                    </button>
                  )
                ) : txOngoing && txOngoingData.isDeposit === false && txOngoingData.trancheCardId === trancheCard.id ? (
                  <button>
                    <LoadingButton>
                      {[...Array(4).keys()].map((idx) => {
                        return <LoadingButtonCircle i={idx + 1}></LoadingButtonCircle>;
                      })}
                    </LoadingButton>
                  </button>
                ) : (
                  <button onClick={() => openModal('trancheEnable', false)}>
                    <img src={BtnArrow} alt='arrow' />
                    <h2>Approve</h2>
                  </button>
                )}
              </Form>
            </TableMoreRightSection>
          </TableMoreRowContentRight>
        ) : (
          <TableMoreRowContentRight>
            {formType === 'deposit' ? (
              <TableMoreRightSection
                color={ModeThemes[theme].dropDownBorder}
                disabledBackground={ModeThemes[theme].inputDisabledBackground}
                btn={ModeThemes[theme].backgroundBorder}
                // loading={txOngoing}
                disabled={!isDepositApproved}
                approved={!isDepositApproved}
                disabledColor={ModeThemes[theme].DisabledBtn}
                disabledTextColor={ModeThemes[theme].DisabledBtnText}
              >
                {txOngoing && (
                  <div>
                    <ReactLoading type={'spin'} color={ModeThemes[theme].loadingSpinner} />
                  </div>
                )}
                <TableMoreTitleWrapper color={ModeThemes[theme].dropDownText} theme={theme} textwithBtn={cryptoType === 'WFTM'}>
                  <MobileMoreFormBtns color={ModeThemes[theme].dropDownText}>
                    <MobileMoreFormBtn current={formType === 'deposit'} onClick={() => setFormType('deposit')} color={ModeThemes[theme].dropDownText}>
                      {i18n.t('tranche.trancheData.deposit')}
                    </MobileMoreFormBtn>
                    <MobileMoreFormBtn
                      current={formType === 'withdraw'}
                      onClick={() => setFormType('withdraw')}
                      color={ModeThemes[theme].dropDownText}
                    >
                      {i18n.t('tranche.trancheData.withdraw')}
                    </MobileMoreFormBtn>
                  </MobileMoreFormBtns>
                  {cryptoType === 'WFTM' && <button onClick={() => openModal('trancheWFTM', true)}>Wrap</button>}
                  {/* <CheckboxWrapper hidden={isEth}>
                    <h2>{isDepositApproved ? i18n.t('tranche.trancheData.enabled') : i18n.t('tranche.trancheData.disabled')}</h2>

                    <CheckboxContent disabled={txOngoing}>
                      <Field
                        component='input'
                        type='checkbox'
                        name='depositIsApproved'
                        id='depositIsApproved'
                        checked={isDepositApproved}
                        disabled={txOngoing}
                      />
                      <label
                        onClick={txOngoing ? () => {} : (e) => approveContract(true, isDepositApproved, e)}
                        htmlFor='depositIsApproved'
                      ></label>
                    </CheckboxContent>
                  </CheckboxWrapper> */}
                </TableMoreTitleWrapper>

                <h2>
                  {i18n.t('tranche.trancheData.balance')}: {buyerTokenBalance ? roundNumber(buyerTokenBalance) : '0'} <span>{cryptoType}</span>
                </h2>
                <Form onSubmit={(e) => e.preventDefault()} noValidate>
                  <FormContent
                    color={ModeThemes[theme].dropDownText}
                    background={ModeThemes[theme].inputBackground}
                    disabledBackground={ModeThemes[theme].inputDisabledBackground}
                    btn={ModeThemes[theme].backgroundBorder}
                  >
                    <Field
                      component='input'
                      validate={[number]}
                      onChange={(e, newValue) => handleInputChange(newValue, true)}
                      disabled={!isDepositApproved}
                      className={depositBalanceCheck}
                      name='depositAmount'
                      type='number'
                      step='0.001'
                    />
                    {!isEth && <h2 onClick={isDepositApproved ? (e) => setMaxAmount(e, true) : undefined}>{i18n.t('tranche.trancheData.max')}</h2>}
                  </FormContent>
                  {isDepositApproved ? (
                    <button
                      onClick={() => openModal('trancheConfirm', true)}
                      disabled={depositBalanceCheck === 'InputStylingError' || !formValues || +formValues.depositAmount <= 0 || isNaN(+formValues.depositAmount)}
                    >
                      <img src={BtnArrow} alt='arrow' />
                      <h2>{i18n.t('tranche.trancheData.deposit')}</h2>
                    </button>
                  ) : (
                    <button onClick={() => openModal('trancheEnable', true)}>
                      <img src={BtnArrow} alt='arrow' />
                      <h2>Approve</h2>
                    </button>
                  )}
                </Form>
              </TableMoreRightSection>
            ) : (
              <TableMoreRightSection
                withdraw
                color={ModeThemes[theme].dropDownBorder}
                disabledBackground={ModeThemes[theme].inputDisabledBackground}
                btn={ModeThemes[theme].backgroundBorder}
                // loading={txOngoing}
                disabled={!isWithdrawApproved}
                approved={!isWithdrawApproved}
                disabledColor={ModeThemes[theme].DisabledBtn}
                disabledTextColor={ModeThemes[theme].DisabledBtnText}
              >
                {txOngoing && (
                  <div>
                    <ReactLoading type={'spin'} color={ModeThemes[theme].loadingSpinner} />
                  </div>
                )}
                <TableMoreTitleWrapper color={ModeThemes[theme].dropDownText}>
                  <MobileMoreFormBtns color={ModeThemes[theme].dropDownText}>
                    <MobileMoreFormBtn current={formType === 'deposit'} onClick={() => setFormType('deposit')} color={ModeThemes[theme].dropDownText}>
                      {i18n.t('tranche.trancheData.deposit')}
                    </MobileMoreFormBtn>
                    <MobileMoreFormBtn
                      current={formType === 'withdraw'}
                      onClick={() => setFormType('withdraw')}
                      color={ModeThemes[theme].dropDownText}
                    >
                      {i18n.t('tranche.trancheData.withdraw')}
                    </MobileMoreFormBtn>
                  </MobileMoreFormBtns>
                  {/* <CheckboxWrapper>
                    <h2>{isWithdrawApproved ? i18n.t('tranche.trancheData.enabled') : i18n.t('tranche.trancheData.disabled')}</h2>

                    <CheckboxContent disabled={txOngoing}>
                      <Field
                        component='input'
                        type='checkbox'
                        name='withdrawIsApproved'
                        id='withdrawIsApproved'
                        checked={isWithdrawApproved}
                        disabled={txOngoing}
                      />
                      <label
                        onClick={txOngoing ? () => {} : (e) => approveContract(false, isWithdrawApproved, e)}
                        htmlFor='withdrawIsApproved'
                      ></label>
                    </CheckboxContent>
                  </CheckboxWrapper> */}
                </TableMoreTitleWrapper>
                <h2>
                  {i18n.t('tranche.trancheData.balance')}: {trancheTokenBalance ? roundNumber(trancheTokenBalance) : '0'} <span>{trancheToken}</span>
                </h2>
                <Form onSubmit={(e) => e.preventDefault()} noValidate>
                  <FormContent
                    color={ModeThemes[theme].dropDownText}
                    background={ModeThemes[theme].inputBackground}
                    disabledBackground={ModeThemes[theme].inputDisabledBackground}
                    btn={ModeThemes[theme].backgroundBorder}
                  >
                    <Field
                      component='input'
                      validate={[number]}
                      onChange={(e, newValue) => handleInputChange(newValue, false)}
                      disabled={!isWithdrawApproved}
                      className={withdrawBalanceCheck}
                      name='withdrawAmount'
                      type='number'
                      step='0.001'
                    />
                    <h2 onClick={isWithdrawApproved ? (e) => setMaxAmount(e, false) : undefined}>{i18n.t('tranche.trancheData.max')}</h2>
                  </FormContent>
                  {isWithdrawApproved ? (
                    <button
                      onClick={() => openModal('trancheConfirm', false)}
                      disabled={withdrawBalanceCheck === 'InputStylingError' || !formValues || +formValues.withdrawAmount <= 0 || isNaN(+formValues.withdrawAmount)}
                    >
                      <img src={BtnArrow} alt='arrow' />
                      <h2>{i18n.t('tranche.trancheData.withdraw')}</h2>
                    </button>
                  ) : (
                    <button onClick={() => openModal('trancheEnable', false)}>
                      <img src={BtnArrow} alt='arrow' />
                      <h2>Approve</h2>
                    </button>
                  )}
                </Form>
              </TableMoreRightSection>
            )}
          </TableMoreRowContentRight>
        )}
      </TableMoreRowContent>
    </TableMoreRowWrapper>
  );
};

TableMoreRow = reduxForm({
  form: 'tranche',
  initialValues: { depositAmount: '', withdrawAmount: '' }
  // destroyOnUnmount: false
})(TableMoreRow);

const mapStateToProps = (state) => ({
  ethereum: state.ethereum,
  data: state.data,
  formValues: getFormValues('tranche')(state),
  theme: state.theme
});

export default TableMoreRow = connect(mapStateToProps, {
  change,
  setTxModalOpen,
  setTxModalType,
  setTxModalStatus,
  setTxModalData,
  setTxModalLoading,
  setAddress,
  setNetwork,
  setBalance,
  setWalletAndWeb3
})(TableMoreRow);
