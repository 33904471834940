import Web3 from 'web3';
import axios from 'axios';
import store from '../store';
import { ERC20Setup, isEqualTo, isGreaterThan, SLICESetup } from 'utils';
import {
  networkId,
  maticNetworkId,
  maticAddress,
  fantomNetworkId,
  avalancheNetworkId,
  serverUrl,
  etherScanUrl,
  maticBlockExplorerUrl,
  fantomBlockExplorerUrl,
  apiUri,
  ERC20Tokens,
  CompTrancheTokens,
  TrancheBuyerCoinAddresses,
  AaveTrancheTokens,
  PolygonBuyerCoinAddresses,
  YearnTrancheTokens,
  FantomBuyerCoinAddresses,
  StakingAddresses,
  LockupAddress,
  SLICEAddress,
  LP1TokenAddress,
  LP2TokenAddress,
  RewardDistributionAddress,
  AvalancheBuyerCoinAddresses,
  AvalancheTrancheTokens,
  avalancheBlockExplorerUrl,
  BenqiBuyerCoinAddresses,
  BenqiTrancheTokens
} from 'config';
import {
  SET_ADDRESS,
  SET_NETWORK,
  SET_BALANCE,
  SET_TOKEN_BALANCE,
  SET_WALLET,
  SET_WEB3,
  SET_CURRENT_BLOCK,
  SET_TRANSACTION_LOADING,
  SET_ALLOWANCE,
  SET_BLOCKEXPLORER_URL,
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_COUNT,
  REMOVE_NOTIFICATION,
  SIR_REWARDS,
  SET_DELEGATEE,
  SET_VOTES
} from './constants';
import { summaryFetchSuccess } from './summaryData';
import { setHasMigrated, trancheMarketsToggle } from './tableData';
import { fromWei, getUnclaimedRewards } from 'services';

const { stakingSummary } = apiUri;

export const setAddress = (address) => (dispatch) => {
  const migrateAddress = JSON.parse(window.localStorage.getItem('migrateAddress'));
  migrateAddress && migrateAddress[address.toLowerCase()] ? store.dispatch(setHasMigrated(true)) : store.dispatch(setHasMigrated(false));
  if (address) {
    dispatch({
      type: SET_ADDRESS,
      payload: address.toLowerCase()
    });
    window.localStorage.setItem('address', address.toLowerCase());
  }
};

export const setNetwork = (network) => async (dispatch) => {
  const state = store.getState();
  const { path, ethereum, data: { trancheMarket } } = state;
  const { address, wallet } = ethereum;
  dispatch({
    type: SET_NETWORK,
    payload: +wallet.provider.chainId
  });
  const localNetwork = window.localStorage.getItem('network');
  window.localStorage.setItem('network', +wallet.provider.chainId === networkId
    ? 'ethereum'
    : (+wallet.provider.chainId === maticNetworkId
      ? 'polygon'
      : (+wallet.provider.chainId === fantomNetworkId
        ? 'ftm'
        : (+wallet.provider.chainId === avalancheNetworkId
          ? (trancheMarket === 'benqi' || (!trancheMarket && localNetwork === 'benqi') ? 'benqi' : 'avax')
          : 'ethereum'))));

  if (+wallet.provider.chainId === networkId) {
    store.dispatch(trancheMarketsToggle('compound'));
    store.dispatch(setBlockExplorerUrl(etherScanUrl));
    if (path === 'stake' && address) {
      const res = await axios(`${serverUrl + stakingSummary + address}`);
      const { result } = res.data;
      store.dispatch(summaryFetchSuccess(result));
    }
  }
  if (+wallet.provider.chainId === maticNetworkId) {
    store.dispatch(trancheMarketsToggle('aavePolygon'));
    store.dispatch(setBlockExplorerUrl(maticBlockExplorerUrl));
  }
  if (+wallet.provider.chainId === fantomNetworkId) {
    store.dispatch(trancheMarketsToggle('fantom'));
    store.dispatch(setBlockExplorerUrl(fantomBlockExplorerUrl));
  }
  if (+wallet.provider.chainId === avalancheNetworkId)
  {
    store.dispatch(trancheMarketsToggle(trancheMarket === 'benqi'? 'benqi' : 'avalanche'));
    store.dispatch(setBlockExplorerUrl(avalancheBlockExplorerUrl));
  }
  +wallet.provider.chainId !== maticNetworkId &&
    +wallet.provider.chainId !== fantomNetworkId &&
    +wallet.provider.chainId !== avalancheNetworkId &&
    store.dispatch(setBlockExplorerUrl(etherScanUrl));
};

export const setBalance = (balance) => (dispatch) => {
  const state = store.getState();
  const { network } = state.ethereum;
  dispatch({
    type: SET_BALANCE,
    payload: balance
  });
  if (network === maticNetworkId || network === avalancheNetworkId) {
    dispatch({
      type: SET_TOKEN_BALANCE,
      payload: { tokenAddress: maticAddress, tokenBalance: balance }
    });
  }
};

export const setWalletAndWeb3 = (wallet) => async (dispatch) => {
  wallet.provider.removeAllListeners([ 'networkChanged' ]);
  let web3 = new Web3(wallet.provider);
  dispatch({
    type: SET_WALLET,
    payload: wallet
  });
  dispatch({
    type: SET_WEB3,
    payload: web3
  });
  window.localStorage.setItem('selectedWallet', wallet.name);
};

export const setBlockExplorerUrl = (url) => (dispatch) => {
  dispatch({
    type: SET_BLOCKEXPLORER_URL,
    payload: url
  });
};

export const setTokenBalance = (tokenAddress, address) => async (dispatch) => {
  try {
    const state = store.getState();
    const { web3 } = state.ethereum;
    const token = ERC20Setup(web3, tokenAddress);
    const tokenBalance = await token.methods.balanceOf(address).call();

    dispatch({
      type: SET_TOKEN_BALANCE,
      payload: { tokenAddress, tokenBalance }
    });
  } catch (error) {
    console.error(error);
  }
};

export const setTokenBalances = (address) => async (dispatch) => {
  try {
    const state = store.getState();
    let { web3, network, maticWeb3, fantomWeb3, avaxWeb3 } = state.ethereum;
    let { trancheMarket } = state.data;
    web3 = network === maticNetworkId
      ? maticWeb3.http
      : network === fantomNetworkId
        ? fantomWeb3.http
        : network === avalancheNetworkId
          ? avaxWeb3.http
          : web3;
    const Tokens =
      network === networkId
        ? ERC20Tokens.concat(CompTrancheTokens)
        : network === maticNetworkId
          ? PolygonBuyerCoinAddresses.concat(AaveTrancheTokens)
          : network === fantomNetworkId
            ? FantomBuyerCoinAddresses.concat(YearnTrancheTokens)
            : network === avalancheNetworkId && trancheMarket === 'benqi'
              ? BenqiBuyerCoinAddresses.concat(BenqiTrancheTokens)
              : network === avalancheNetworkId
                ? AvalancheBuyerCoinAddresses.concat(AvalancheTrancheTokens)
                : [];
    if (network === networkId
      || network === maticNetworkId
      || network === fantomNetworkId
      || network === avalancheNetworkId)
    {
      // const batch = new web3.BatchRequest();
      // const tokenBalance = {};
      let promises = Tokens.map(async (tokenAddress) => {
        let token = ERC20Setup(web3, tokenAddress);
        const balance = await token.methods.balanceOf(address).call();
        return { tokenAddress: tokenAddress.toLowerCase(), tokenBalance: balance };
      });
      // batch.execute();
      promises = await Promise.all(promises);
      promises.forEach((payload) => {
        if (payload)
        {
          delete payload.address;
          dispatch({
            type: SET_TOKEN_BALANCE,
            payload
          });
        }
      });
      (network === maticNetworkId || network === fantomNetworkId || network === avalancheNetworkId) &&
        dispatch({
          type: SET_TOKEN_BALANCE,
          payload: { tokenAddress: SLICEAddress.toLowerCase(), tokenBalance: '0' }
        });
    }
  } catch (error) {
    console.error(error);
  }
};

export const toggleApproval = (tokenAddress, contractAddress, bool) => async (dispatch) => {
  dispatch({
    type: SET_ALLOWANCE,
    payload: { contractAddress, tokenAddress: tokenAddress.toLowerCase(), isApproved: bool }
  });
};

export const checkTrancheAllowances = (address, contractAddress) => async (dispatch) => {
  try {
    const state = store.getState();
    let { web3, network, maticWeb3, fantomWeb3, avaxWeb3 } = state.ethereum;
    let { trancheMarket } = state.data;
    // if (
    //   (network === networkId && contractAddress === JAaveAddress) ||
    //   (network === maticNetworkId && contractAddress === JCompoundAddress) ||
    //   (network === fantomNetworkId && contractAddress === JYearnAddress)
    // )
    //   return;
    web3 = network === maticNetworkId
      ? maticWeb3.http
      : network === fantomNetworkId
        ? fantomWeb3.http
        : network === avalancheNetworkId
          ? avaxWeb3.http : web3;
    const Tokens =
      network === networkId
        ? CompTrancheTokens.concat(TrancheBuyerCoinAddresses)
        : network === maticNetworkId
          ? AaveTrancheTokens.concat(PolygonBuyerCoinAddresses)
          : network === fantomNetworkId
            ? YearnTrancheTokens.concat(FantomBuyerCoinAddresses)
            : network === avalancheNetworkId && trancheMarket === 'benqi'
              ? BenqiTrancheTokens.concat(BenqiBuyerCoinAddresses)
              : network === avalancheNetworkId
                ? AvalancheTrancheTokens.concat(AvalancheBuyerCoinAddresses)
                : [];
    if (network === networkId
      || network === maticNetworkId
      || network === fantomNetworkId
      || network === avalancheNetworkId)
    {
      

      let promises = Tokens.map(async (tokenAddress) => {
        let token = ERC20Setup(web3, tokenAddress);
        const balance = await token.methods.balanceOf(address).call();
        return { tokenAddress: tokenAddress.toLowerCase(), tokenBalance: balance };
      });
      // batch.execute();
      promises = await Promise.all(promises);
      const tokenBalance = promises.reduce((acc, o) => {
        acc[ o.tokenAddress ] = o.tokenBalance;
        return acc;
      }, {});

      promises = Tokens.map(async (tokenAddress) => {
        let token = ERC20Setup(web3, tokenAddress);
        const balance = await token.methods.allowance(address, contractAddress).call({ from: address }).catch(e => 0);
        return balance;
      });
      promises = await Promise.all(promises);

      promises.forEach((res, i) => { 
        if ((isGreaterThan(res, tokenBalance[Tokens[i].toLowerCase()]) || isEqualTo(res, tokenBalance[Tokens[i].toLowerCase()])) && res !== '0') {
          dispatch({
            type: SET_ALLOWANCE,
            payload: { contractAddress, tokenAddress: Tokens[i].toLowerCase(), isApproved: true }
          });
        } else {
          dispatch({
            type: SET_ALLOWANCE,
            payload: { contractAddress, tokenAddress: Tokens[i].toLowerCase(), isApproved: false }
          });
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
};

// export const checkTrancheAllowances = (address, contractAddress) => async (dispatch) => {
//   try {
//     const state = store.getState();
//     let { web3, network, maticWeb3 } = state.ethereum;
//     if ((network === networkId && contractAddress === JAaveAddress) || (network === maticNetworkId && contractAddress === JCompoundAddress)) return;
//     web3 = network === maticNetworkId ? maticWeb3.http : web3;
//     const Tokens =
//       network === networkId
//         ? CompTrancheTokens.concat(TrancheBuyerCoinAddresses)
//         : network === maticNetworkId
//         ? AaveTrancheTokens.concat(PolygonBuyerCoinAddresses)
//         : [];
//     if (network === networkId || network === maticNetworkId) {
//       const batch = new web3.BatchRequest();
//       let tokenBalance = {};
//       Tokens.map((tokenAddress) => {
//         let token = ERC20Setup(web3, tokenAddress);
//         batch.add(
//           token.methods.balanceOf(address).call.request({ from: address }, (err, res) => {
//             if (err) {
//               console.error(err);
//             } else {
//               tokenBalance[tokenAddress] = res;
//             }
//           })
//         );
//         return batch;
//       });
//       Tokens.map((tokenAddress) => {
//         let token = ERC20Setup(web3, tokenAddress);
//         batch.add(
//           token.methods.allowance(address, contractAddress).call.request({ from: address }, (err, res) => {
//             if (err) {
//               console.error(err);
//             } else {
//               if ((isGreaterThan(res, tokenBalance[tokenAddress]) || isEqualTo(res, tokenBalance[tokenAddress])) && res !== '0') {
//                 dispatch({
//                   type: SET_ALLOWANCE,
//                   payload: { contractAddress, tokenAddress: tokenAddress.toLowerCase(), isApproved: true }
//                 });
//               } else {
//                 dispatch({
//                   type: SET_ALLOWANCE,
//                   payload: { contractAddress, tokenAddress: tokenAddress.toLowerCase(), isApproved: false }
//                 });
//               }
//             }
//           })
//         );
//         return batch;
//       });
//       batch.execute();
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

export const checkStakingAllowances = (address) => (dispatch) => {
  try {
    const state = store.getState();
    let { web3 } = state.ethereum;

    const sliceStakingContracts = [StakingAddresses[0], LockupAddress];
    const Tokens = [SLICEAddress, LP1TokenAddress, LP2TokenAddress];
    let tokenBalance = {};

    const batch = new web3.BatchRequest();
    Tokens.map((tokenAddress) => {
      let token = ERC20Setup(web3, tokenAddress);
      batch.add(
        token.methods.balanceOf(address).call.request({ from: address }, (err, res) => {
          if (err) {
            console.error(err);
          } else {
            tokenBalance[tokenAddress] = res;
          }
        })
      );
      return batch;
    });

    let SLICE = ERC20Setup(web3, SLICEAddress);
    let LP1 = ERC20Setup(web3, LP1TokenAddress);
    let LP2 = ERC20Setup(web3, LP2TokenAddress);

    sliceStakingContracts.map((contractAddress) => {
      batch.add(
        SLICE.methods.allowance(address, contractAddress).call.request({ from: address }, (err, res) => {
          if (err) {
            console.error(err);
          } else {
            if ((isGreaterThan(res, tokenBalance[SLICEAddress]) || isEqualTo(res, tokenBalance[SLICEAddress])) && res !== '0') {
              dispatch({
                type: SET_ALLOWANCE,
                payload: { contractAddress: contractAddress.toLowerCase(), tokenAddress: SLICEAddress.toLowerCase(), isApproved: true }
              });
            } else {
              dispatch({
                type: SET_ALLOWANCE,
                payload: { contractAddress: contractAddress.toLowerCase(), tokenAddress: SLICEAddress.toLowerCase(), isApproved: false }
              });
            }
          }
        })
      );
      return batch;
    });
    batch.add(
      LP1.methods.allowance(address, StakingAddresses[1]).call.request({ from: address }, (err, res) => {
        if (err) {
          console.error(err);
        } else {
          if ((isGreaterThan(res, tokenBalance[LP1TokenAddress]) || isEqualTo(res, tokenBalance[LP1TokenAddress])) && res !== '0') {
            dispatch({
              type: SET_ALLOWANCE,
              payload: { contractAddress: StakingAddresses[1].toLowerCase(), tokenAddress: LP1TokenAddress.toLowerCase(), isApproved: true }
            });
          } else {
            dispatch({
              type: SET_ALLOWANCE,
              payload: { contractAddress: StakingAddresses[1].toLowerCase(), tokenAddress: LP1TokenAddress.toLowerCase(), isApproved: false }
            });
          }
        }
      })
    );
    batch.add(
      LP2.methods.allowance(address, StakingAddresses[2]).call.request({ from: address }, (err, res) => {
        if (err) {
          console.error(err);
        } else {
          if ((isGreaterThan(res, tokenBalance[LP2TokenAddress]) || isEqualTo(res, tokenBalance[LP2TokenAddress])) && res !== '0') {
            dispatch({
              type: SET_ALLOWANCE,
              payload: { contractAddress: StakingAddresses[2].toLowerCase(), tokenAddress: LP2TokenAddress.toLowerCase(), isApproved: true }
            });
          } else {
            dispatch({
              type: SET_ALLOWANCE,
              payload: { contractAddress: StakingAddresses[2].toLowerCase(), tokenAddress: LP2TokenAddress.toLowerCase(), isApproved: false }
            });
          }
        }
      })
    );

    batch.execute();
  } catch (error) {
    console.error(error);
  }
};

export const setCurrentBlock = (blockNumber) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_BLOCK,
    payload: blockNumber
  });
};

export const setTxLoading = (bool) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTION_LOADING,
    payload: bool
  });
};

export const setNotificationCount = (count) => (dispatch) => {
  dispatch({
    type: UPDATE_NOTIFICATION_COUNT,
    payload: count
  });
};

export const addNotification = (notification) => (dispatch) => {
  dispatch({
    type: ADD_NOTIFICATION,
    payload: notification
  });
};

export const updateNotification = (notification) => (dispatch) => {
  const state = store.getState();
  let { notifications } = state.ethereum;
  notifications.find((element) => element.id === notification.id)
    ? dispatch({
        type: UPDATE_NOTIFICATION,
        payload: notification
      })
    : dispatch({
        type: ADD_NOTIFICATION,
        payload: notification
      });
};

export const removeNotification = (notification) => (dispatch) => {
  const state = store.getState();
  let { notifications } = state.ethereum;
  const index = notifications.indexOf(notification);
  dispatch({
    type: REMOVE_NOTIFICATION,
    payload: index
  });
};

export const checkSIRRewards = () => async (dispatch) => {
  const rewards = await getUnclaimedRewards(RewardDistributionAddress);
  dispatch({
    type: SIR_REWARDS,
    payload: rewards
  });
};

export const governanceChecks = (address) => async (dispatch) => {
  const state = store.getState();
  const { web3 } = state.ethereum;
  const Slice = await SLICESetup(web3);
  try {
    const batch = new web3.BatchRequest();
    batch.add(
      Slice.methods.delegates(address).call.request({ from: address }, (err, res) => {
        if (err) {
          console.error(err);
        } else {
          dispatch({
            type: SET_DELEGATEE,
            payload: res
          });
        }
      })
    );
    batch.add(
      Slice.methods.getCurrentVotes(address).call.request({ from: address }, (err, res) => {
        if (err) {
          console.error(err);
        } else {
          dispatch({
            type: SET_VOTES,
            payload: fromWei(res)
          });
        }
      })
    );
    batch.execute();
  } catch (error) {
    console.error(error);
  }
  // const delegatee = await delegateCheck(address);
  // console.log(delegatee);
  // dispatch({
  //   type: SET_DELEGATEE,
  //   payload: delegatee
  // });
};

export const setAllowanceAndBalance = (address, contractAddress, tokens) => async (dispatch) => {
  const state = store.getState();
  let { web3, network, maticWeb3, fantomWeb3, avaxWeb3 } = state.ethereum;
  web3 = network === maticNetworkId
    ? maticWeb3.http
    : network === fantomNetworkId
      ? fantomWeb3.http
      : network === avalancheNetworkId
        ? avaxWeb3.http
      : web3;
  let promises = tokens.map(async (tokenAddress) => {
    let token = ERC20Setup(web3, tokenAddress);
    const balance = await token.methods.balanceOf(address).call().catch(e => 0);
    return { tokenAddress: tokenAddress.toLowerCase(), tokenBalance: balance };
  });
  // batch.execute();
  const balances = await Promise.all(promises);
  balances.forEach((payload) => {
    if (payload)
    {
      dispatch({
        type: SET_TOKEN_BALANCE,
        payload
      });
    }
  });
  const tokenBalance = balances.reduce((acc, o) => {
    acc[ o.tokenAddress ] = o.tokenBalance;
    return acc;
  }, {});
    
  promises = tokens.map(async (tokenAddress) => {
    let token = ERC20Setup(web3, tokenAddress);
    const balance = await token.methods.allowance(address, contractAddress).call({ from: address }).catch(e => 0);
    return balance;
  });
  promises = await Promise.all(promises);
  promises.forEach((res, i) => { 
    if ((isGreaterThan(res, tokenBalance[tokens[i]]) || isEqualTo(res, tokenBalance[tokens[i]])) && res !== '0') {
      dispatch({
        type: SET_ALLOWANCE,
        payload: { contractAddress, tokenAddress: tokens[i].toLowerCase(), isApproved: true }
      });
    } else {
      dispatch({
        type: SET_ALLOWANCE,
        payload: { contractAddress, tokenAddress: tokens[i].toLowerCase(), isApproved: false }
      });
    }
  });
}
