import axios from 'axios';
import store from 'redux/store';
import { web3, timeout } from 'utils';
// import maticWeb3 from 'utils/maticWeb3';
import { fetchTableData, trancheCardToggle, fetchUserStakingList } from 'redux/actions/tableData';
import { summaryFetchSuccess, setSliceStats, setTvl } from 'redux/actions/summaryData';
import { setTokenBalances } from 'redux/actions/ethereum';
import { serverUrl, apiUri, StakingAddresses, YieldAddresses, LockupAddress, JCompoundAddress, JAaveAddress, JYearnAddress, JAvalancheAddress, JBenqiAddress } from 'config/constants';
import maticWeb3 from 'utils/maticWeb3';
import fantomWeb3 from 'utils/fantomWeb3';
import avaxWeb3 from 'utils/avaxWeb3';
const { tranchesList, stakingList, stakingSummary, sliceSummary, totalValueLocked, userStakingList } = apiUri;

let JCompound, Staking, YieldFarm, JAave, Lockup, JYearn, JAvalanche, JBenqi;

export const ETHContracts = {
  subscribe: () => {
    try {
      const state = store.getState();
      const { path, ethereum, data } = state;
      const { address } = ethereum;

      JCompound =
        path === 'tranche' &&
        address &&
        web3.eth
          .subscribe('logs', {
            address: JCompoundAddress
          })
          .on('data', async (log) => {
            let userAddress = address.split('0x')[1];
            await timeout(6000);
            if (log.data.includes(userAddress)) {
              const state = store.getState();
              const { data } = state;
              const { skip, limit, filter } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: filter,
                      contractAddress: JCompoundAddress
                    }
                  },
                  tranchesList
                )
              );
              store.dispatch(trancheCardToggle({ status: false, id: null }));
              const getSliceStats = async () => {
                const res = await axios(`${serverUrl}${sliceSummary}`);
                const { result } = res.data;
                store.dispatch(setSliceStats(result));
              };
              const getTvl = async () => {
                const res = await axios(`${serverUrl + totalValueLocked}`);
                const { result } = res.data;
                store.dispatch(setTvl(result));
              };
              getSliceStats();
              getTvl();
              store.dispatch(setTokenBalances(address));
            }
          });
      Staking =
        path === 'stake' &&
        address &&
        web3.eth
          .subscribe('logs', {
            address: StakingAddresses
          })
          .on('data', async (log) => {
            let userAddress = '0x000000000000000000000000' + address.split('0x')[1];
            if (log.topics.includes(userAddress)) {
              await timeout(6000);
              const { skip, limit, filter } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: filter
                    }
                  },
                  stakingList
                )
              );
              const res = await axios(`${serverUrl}${stakingSummary}${address}`);
              const { result } = res.data;
              store.dispatch(summaryFetchSuccess(result));
              store.dispatch(setTokenBalances(address));
            }
          });
      YieldFarm =
        path === 'stake' &&
        address &&
        web3.eth
          .subscribe('logs', {
            address: YieldAddresses
          })
          .on('data', async (log) => {
            let userAddress = '0x000000000000000000000000' + address.split('0x')[1];
            if (log.topics.includes(userAddress)) {
              await timeout(6000);
              const res = await axios(`${serverUrl}${stakingSummary}${address}`);
              const { result } = res.data;
              store.dispatch(summaryFetchSuccess(result));
              store.dispatch(setTokenBalances(address));
            }
          });
      Lockup =
        path === 'stake' &&
        address &&
        web3.eth
          .subscribe('logs', {
            address: LockupAddress
          })
          .on('data', async (log) => {
            console.log(log);
            console.log(new Date().toUTCString());
            let userAddress = '0x000000000000000000000000' + address.split('0x')[1];
            if (log.topics.includes(userAddress)) {
              await timeout(6000);
              const { skip, limit, filter } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: filter
                    }
                  },
                  stakingList
                )
              );
              await store.dispatch(fetchUserStakingList(`${userStakingList}/${address}`));
              const res = await axios(`${serverUrl}${stakingSummary}${address}`);
              const { result } = res.data;
              store.dispatch(summaryFetchSuccess(result));
              store.dispatch(setTokenBalances(address));
            }
          });
    } catch (error) {
      console.error(error);
    }
  },
  unsubscribe: () => {
    try {
      JCompound &&
        JCompound.unsubscribe((error) => {
          if (error) console.error(error);
        });
      Staking &&
        Staking.unsubscribe((error) => {
          if (error) console.error(error);
        });
      YieldFarm &&
        YieldFarm.unsubscribe((error) => {
          if (error) console.error(error);
        });
      Lockup &&
        Lockup.unsubscribe((error) => {
          if (error) console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const MaticContracts = {
  subscribe: () => {
    try {
      const state = store.getState();
      const { path, ethereum } = state;
      const { address } = ethereum;

      JAave =
        path === 'tranche' &&
        address &&
        maticWeb3.webSocket.eth
          .subscribe('logs', {
            address: JAaveAddress
          })
          .on('data', async (log) => {
            console.log(log);
            let userAddress = address.split('0x')[1];
            await timeout(6000);
            if (log.data.includes(userAddress)) {
              const state = store.getState();
              const { data } = state;
              const { skip, limit, filter } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: filter,
                      contractAddress: JAaveAddress
                    }
                  },
                  tranchesList
                )
              );
              store.dispatch(trancheCardToggle({ status: false, id: null }));
              const getSliceStats = async () => {
                const res = await axios(`${serverUrl + sliceSummary}`);
                const { result } = res.data;
                store.dispatch(setSliceStats(result));
              };
              const getTvl = async () => {
                const res = await axios(`${serverUrl}${totalValueLocked}`);
                const { result } = res.data;
                store.dispatch(setTvl(result));
              };
              getSliceStats();
              getTvl();
              store.dispatch(setTokenBalances(address));
            }
          });
    } catch (error) {
      console.error(error);
    }
  },
  unsubscribe: () => {
    try {
      JAave &&
        JAave.unsubscribe((error) => {
          if (error) console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const FantomContracts = {
  subscribe: () => {
    try {
      const state = store.getState();
      const { path, ethereum } = state;
      const { address } = ethereum;
      JYearn =
        path === 'tranche' &&
        address &&
        fantomWeb3.webSocket.eth
          .subscribe('logs', {
            address: JYearnAddress
          })
          .on('data', async (log) => {
            console.log(log);
            let userAddress = address.split('0x')[1];
            await timeout(6000);
            if (log.data.includes(userAddress)) {
              const state = store.getState();
              const { data } = state;
              const { skip, limit, filter } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: filter,
                      contractAddress: JYearnAddress
                    }
                  },
                  tranchesList
                )
              );
              store.dispatch(trancheCardToggle({ status: false, id: null }));
              const getSliceStats = async () => {
                const res = await axios(`${serverUrl + sliceSummary}`);
                const { result } = res.data;
                store.dispatch(setSliceStats(result));
              };
              const getTvl = async () => {
                const res = await axios(`${serverUrl}${totalValueLocked}`);
                const { result } = res.data;
                store.dispatch(setTvl(result));
              };
              getSliceStats();
              getTvl();
              store.dispatch(setTokenBalances(address));
            }
          });
    } catch (error) {
      console.error(error);
    }
  },
  unsubscribe: () => {
    try {
      JYearn &&
        JYearn.unsubscribe((error) => {
          if (error) console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const AvalancheContracts = {
  subscribe: () => {
    try {
      const state = store.getState();
      const { path, ethereum } = state;
      const { address } = ethereum;

      JAvalanche =
        path === 'tranche' &&
        address &&
        avaxWeb3.webSocket.eth
          .subscribe('logs', {
            address: JAvalancheAddress
          })
          .on('data', async (log) => {
            console.log(log);
            let userAddress = address.split('0x')[1];
            await timeout(6000);
            if (log.data.includes(userAddress)) {
              const state = store.getState();
              const { data } = state;
              const { skip, limit, filter } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: filter,
                      contractAddress: JAvalancheAddress
                    }
                  },
                  tranchesList
                )
              );
              store.dispatch(trancheCardToggle({ status: false, id: null }));
              const getSliceStats = async () => {
                const res = await axios(`${serverUrl + sliceSummary}`);
                const { result } = res.data;
                store.dispatch(setSliceStats(result));
              };
              const getTvl = async () => {
                const res = await axios(`${serverUrl}${totalValueLocked}`);
                const { result } = res.data;
                store.dispatch(setTvl(result));
              };
              getSliceStats();
              getTvl();
              store.dispatch(setTokenBalances(address));
            }
          });
    } catch (error) {
      console.error(error);
    }
  },
  unsubscribe: () => {
    try {
      JAvalanche &&
        JAvalanche.unsubscribe((error) => {
          if (error) console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

export const BenqiContracts = {
  subscribe: () => {
    try {
      const state = store.getState();
      const { path, ethereum } = state;
      const { address } = ethereum;

      JBenqi =
        path === 'tranche' &&
        address &&
        avaxWeb3.webSocket.eth
          .subscribe('logs', {
            address: JBenqiAddress
          })
          .on('data', async (log) => {
            console.log(log);
            let userAddress = address.split('0x')[1];
            await timeout(6000);
            if (log.data.includes(userAddress)) {
              const state = store.getState();
              const { data } = state;
              const { skip, limit } = data;
              await store.dispatch(
                fetchTableData(
                  {
                    skip,
                    limit,
                    filter: {
                      address: address ? address : undefined,
                      type: 'avax',
                      contractAddress: JBenqiAddress
                    }
                  },
                  tranchesList
                )
              );
              store.dispatch(trancheCardToggle({ status: false, id: null }));
              const getSliceStats = async () => {
                const res = await axios(`${serverUrl + sliceSummary}`);
                const { result } = res.data;
                store.dispatch(setSliceStats(result));
              };
              const getTvl = async () => {
                const res = await axios(`${serverUrl}${totalValueLocked}`);
                const { result } = res.data;
                store.dispatch(setTvl(result));
              };
              getSliceStats();
              getTvl();
              store.dispatch(setTokenBalances(address));
            }
          });
    } catch (error) {
      console.error(error);
    }
  },
  unsubscribe: () => {
    try {
      JBenqi &&
        JBenqi.unsubscribe((error) => {
          if (error) console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }
};

